import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const storage = globalThis.sessionStorage

    const userStore = useUserStore()

    // ! no need reactivity
    if (!userStore.userId) {
        await userStore.userLoad()
    }

    if (userStore.redirect) {
        if (from.fullPath !== '/') {
            storage.setItem('fromRedirect', from.fullPath)
        }
        return navigateTo(userStore.redirect, { external: true })
    }

    const fromRedirect = storage.getItem('fromRedirect')
    if (fromRedirect) {
        navigateTo(fromRedirect)
        storage.removeItem('fromRedirect')
        return fromRedirect
    }
})
