// @see https://github.com/jitbit/HtmlSanitizer/blob/master/HtmlSanitizer.js
export class HtmlSanitizer {
    private tags: string[]
    private attr: string[]
    private protocols: string[]
    private uri: string[]
    private contentTags: string[]

    constructor({ tags = [], attr = [], protocols = [] }: { tags: string[], attr: string[], protocols: string[] }) {
        this.tags = [...new Set([...tags, 'body'])].map(i => i.toUpperCase())
        this.attr = [...new Set(attr)].map(i => i.toLowerCase())
        this.protocols = [...new Set(protocols)].map(i => `${i}:`.toLowerCase())

        this.uri = ['href', 'action']

        this.contentTags = ['FORM', 'GOOGLE-SHEETS-HTML-ORIGIN']
        return this
    }

    clean(input: string) {
        input = input.trim()
        // to save performance
        if (input === '') {
            return ''
        }

        // firefox "bogus node" workaround for wysiwyg's
        if (input === '<br>') {
            return ''
        }

        const _parser = new DOMParser()

        // add "body" otherwise some tags are skipped, like <style>
        if (!input.includes('<body')) {
            input = `<body>${input}</body>`
        }

        const doc = _parser.parseFromString(input, 'text/html')

        // DOM clobbering check (damn you firefox)
        if (doc.body.tagName !== 'BODY') {
            doc.body.remove()
        }
        if (typeof doc.createElement !== 'function') {
            // @ts-expect-error doc.createElement has remove
            doc.createElement.remove()
        }

        const resultElement = this.makeSanitizedCopy(doc, doc.body)
        const html = resultElement.innerHTML

        // replace is just for cleaner code
        return html ? html.replace(/<br[^>]*>(\S)/g, '<br>\n$1').replace(/div><div/g, 'div>\n<div') : ''
    }

    makeSanitizedCopy(doc: Document, node: HTMLElement) {
        let newNode: HTMLElement

        if (node.nodeType === Node.TEXT_NODE) {
            newNode = node.cloneNode(true) as HTMLElement
        } else if (
            node.nodeType === Node.ELEMENT_NODE
            && (this.tags.includes(node.tagName) || this.contentTags.includes(node.tagName))
        ) {
            // is tag allowed?
            if (this.contentTags.includes(node.tagName)) {
                // convert to DIV
                newNode = doc.createElement('DIV')
            } else {
                newNode = doc.createElement(node.tagName)
            }

            let attr
            try {
                attr = node.attributes ? [...node.attributes] : null
            } catch (e) {
                attr = []
            }
            attr?.forEach((attrItem: any) => {
                if (!this.attr.includes(attrItem.name)) {
                    return
                }
                if (attrItem.name === 'style') {
                    let style
                    try {
                        style = Array.prototype.slice.call(node.style)
                    } catch (e) {
                        style = []
                    }

                    style.forEach(styleName => {
                        newNode.style.setProperty(styleName, node.style.getPropertyValue(styleName))
                    })
                } else {
                    if (this.uri.includes(attrItem.name)) {
                        // if this is a "uri" attribute, that can have "javascript:" or something
                        if (
                            attrItem.value.includes(':')
                            && !this.protocols.some(i => attrItem.value.indexOf(i) === 0)
                        ) {
                            return
                        }
                    }
                    newNode.setAttribute(attrItem.name, attrItem.value)
                }
            })

            node.childNodes.forEach((childNode: any) => {
                const subCopy = this.makeSanitizedCopy(doc, childNode)
                newNode.appendChild(subCopy)
            })

            // remove useless empty spans (lots of those when pasting from MS Outlook)
            if (new Set(['SPAN', 'B', 'I', 'U']).has(newNode.tagName) && newNode.innerHTML.trim() === '') {
                return doc.createDocumentFragment() as any
            }
        } else {
            newNode = doc.createDocumentFragment() as any
        }
        return newNode as HTMLElement
    }
}
