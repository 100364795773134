<template>
    <Transition name="loading" :duration="{ enter: 100, leave: 0 }">
        <div v-if="fetchTime" class="loading--wrap">
            <div class="loading--center">
                <svg xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 38 38" class="mb-4">
                    <circle r="15.91549430918954" cx="19" cy="19" fill="none" stroke-width="2" stroke="#dedede" />
                    <circle
                        r="15.91549430918954"
                        cx="19"
                        cy="19"
                        fill="none"
                        stroke-width="2"
                        stroke="var(--app-color)"
                        stroke-linecap="round"
                        stroke-dasharray="25 75"
                        stroke-dashoffset="25"
                        transform="rotate(0 18 18)"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 19 19"
                            to="360 19 19"
                            dur="0.5s"
                            repeatCount="indefinite"
                        />
                    </circle>
                </svg>
                <div class="loading--text">
                    <template v-if="timeExceeded">
                        <div class="mb-4 loading--exceeded">{{ $t('@responseTimedOut') }}</div>
                        <div>
                            {{ $t('@pressESCOr') }} <RBtn
                                mode="border"
                                size="normal"
                                class="mr-1 ml-1"
                                @click="terminate"
                            >
                                {{ $t('@here') }}
                            </RBtn> {{ $t('@toStopTheRequest') }}
                        </div>
                    </template>
                    <div v-else>{{ $t('@processingRequest') }}</div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { fetchAbort, fetchTime } from '~/utils/fetcher'

const delay = 2_000

/**
 * Hooks
 */
onMounted(() => {
    document.addEventListener('keydown', onKeydown)
})
onUnmounted(() => {
    document.removeEventListener('keydown', onKeydown)
})

/**
 * Computed
 */
const timeExceeded = computed(() => fetchTime.value > delay)

/**
 * Methods
 */
function onKeydown(e: KeyboardEvent) {
    if (!timeExceeded.value || !fetchTime) {
        return
    }
    if (e.key !== 'Escape') {
        return
    }
    terminate()
}
function terminate() {
    console.warn('TT')
    fetchAbort.value++
}
</script>

<style lang="scss">
.loading-enter-active,
.loading-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.loading-enter-active {
    transition-delay: 0.1s;
}

.loading-enter-from,
.loading-leave-to {
    opacity: 0;
}

.loading--wrap,
.loading--center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading--center {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 6px;
    padding: 3vh 3vw;
}

.loading--wrap {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #ffffffb3;
    justify-content: center;
}

.loading--wrap {
    text-align: center;
}

.loading--text {
    font-size: 18px;
}

.loading--exceeded {
    color: var(--error);
}
</style>
