<template>
    <Transition name="r-modal-tr">
        <div v-show="tagName" class="r-modal" :class="compClassName">
            <div class="r-modal-overlay" @click="localClose" />
            <div class="r-modal-wrap">
                <div class="r-modal-header" :class="modal?.title || modal?.icon ? '' : 'r-modal-header-no-title'">
                    <RIcon
                        v-if="modal?.icon"
                        :name="modal.icon"
                        class="r-modal-header-icon"
                        :color="modal?.iconColor ?? 'var(--app-color) white'"
                        width="18"
                        height="18"
                    />
                    <div v-if="modal?.title" class="r-modal-header-title">
                        <template v-if="Array.isArray(modal.title)">
                            <span
                                v-for="(it, num) in modal.title"
                                :key="num"
                                :class="`r-modal-header-chunk-title r-title-num-${num + 1}`"
                            >{{ it }}</span>
                        </template>
                        <template v-else>{{ modal.title }}</template>
                    </div>
                    <RBtn
                        :wait="modal?.waiting"
                        mode="positive"
                        icon="cancel"
                        size="big"
                        class="r-modal-close-key"
                        @click="localClose"
                    />
                </div>
                <div class="r-modal-content small-scroll" :style="{ padding: modal?.contentPadding }">
                    <component :is="modal?.component" ref="comp" v-bind="modal?.props" />
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useModal } from '~/components/UiKit'

/**
 * Use
 */
const { modal, close } = useModal()
const route = useRoute()

/**
 * Data
 */
const currentSize = ref('')
const comp = ref<any>(null)

/**
 * Computed
 */
const tagName = computed(() => {
    if (!modal.value?.component) {
        return ''
    }
    let name = modal.value.component.__name
    if (!name && modal.value.component.__file) {
        let tmp = modal.value.component.__file.split('/')
        name = (tmp[tmp.length - 1].split('.')[0])
    }
    if (!name) {
        throw new Error('Cannot find name of component')
    }
    return name
})
const compClassName = computed(() => {
    const list = [`r-modal-${currentSize.value}-size`]
    if (tagName?.value) {
        list.push('open', `r-modal${tagName.value.replace(/[A-Z]/g, (m: string) => `-${m[0].toLowerCase()}`)}`)
    }
    return list.join(' ')
})

/**
 * Watch
 */
watch(() => modal.value?.size, value => {
    if (!value) {
        return
    }
    currentSize.value = value
})
watch(() => route.path, localClose)

/**
 * Hooks
 */
onMounted(() => {
    document.addEventListener('keydown', onKeydown)
})
onUnmounted(() => {
    document.removeEventListener('keydown', onKeydown)
})

/**
 * Methods
 */
function onKeydown(e: KeyboardEvent) {
    if (!modal.value) {
        return
    }
    if (e.key !== 'Escape') {
        return
    }
    localClose()
}

function localClose() {
    if (modal.value?.waiting) {
        return
    }
    // данные из defineExpose
    const defineExposeData = { ...comp.value }
    close(Object.keys(defineExposeData).length ? defineExposeData : undefined)
}
</script>

<style lang="scss">
.r-modal-tr-enter-active,
.r-modal-tr-leave-active {
    transition: opacity 0.4s ease;

    .r-modal-wrap {
        bottom: 0;
        transition: bottom 0.2s ease;
    }
}

.r-modal-tr-enter-from,
.r-modal-tr-leave-to {
    opacity: 0;

    .r-modal-wrap {
        bottom: -50px;
    }
}

.r-modal {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #64646480;
    display: flex;
}

.r-modal-wrap {
    position: relative;
    margin: auto;
    box-sizing: border-box;
    width: 90%;
    max-width: 1000px;
    height: 97%;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.r-modal-small-size .r-modal-wrap {
    width: 800px;
    height: 600px;
}

.r-modal-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.r-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    min-height: 50px;
    background: #dfe8ef;

    .r-modal-header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--app-color);
        border-radius: 0 0 6px 0;
        width: 50px;
        height: 100%;
    }

    .r-modal-header-title {
        padding: 0 2em;
        font-size: 16px;
        box-sizing: border-box;
    }

    .r-modal-header-chunk-title {
        margin-right: 1em;
    }

    .r-modal-close-key {
        margin-left: auto;
        margin-right: 1em;
    }

    &.r-modal-header-no-title {
        position: absolute;
        z-index: 10;
        right: 0;
        background: unset;
    }
}

.r-modal-content {
    width: 100%;
    padding: 2em;
    min-width: 320px;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    flex-grow: 1;
}

.r-modal-close {
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 40px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.r-modal-arr-close {
    position: absolute;
    top: 1px;
    left: 8px;
    font-size: 45px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.r-modal-opened {
    overflow: hidden;

    #container,
    #header {
        filter: blur(3px);
    }
}
</style>
