import ms from 'ms'
import type { FractionSeparator, SegmentsSeparator } from '~/components/ValueViewFormatter/types'

export interface UserSettings {
    langUi: string
    langData: string
    segSep: SegmentsSeparator
    fracSep: FractionSeparator
    mitFlag: boolean
}

export interface UserStore {
    redirect: string
    userId: string
    email: string
    name: string

    settings: UserSettings

    abilities: number[]
}

const updating = ref(false)

export const useUserStore = defineStore('user', {
    state: (): UserStore => ({
        redirect: '',
        userId: '',
        email: '',
        name: '',

        settings: {
            langUi: '',
            langData: '',
            segSep: 'unset',
            fracSep: 'comma',
            mitFlag: false,
        },

        abilities: [],
    }),
    getters: {
        updating() {
            return updating.value
        },
    },
    actions: {
        async userLoad() {
            await loadUserData()
            if (!useUserStore().userId) {
                return
            }

            await useAppStore().getLanguages()

            useConfigStore().$state = await useFetchCall('/configData', {}, { background: true })
        },

        async setSettings(settings: UserSettings) {
            updating.value = true
            this.$state.settings = await useFetchCall('/userSettingsUpdate', settings, { background: true }) as UserSettings
            updating.value = false
        },
    },
})

let reloadTimer: Timer

async function loadUserData() {
    const d = await useFetchCall('/authData', {}, { background: true })
        .catch(_ => {
            clearTimeout(reloadTimer)
            throw new Error('UserLoadError')
        })

    const appStore = useAppStore()

    appStore.langData = d.settings.langData
    appStore.setLangUI(d.settings.langUi)

    useUserStore().$state = d as UserStore

    reloadTimer = setTimeout(() => loadUserData(), ms('15m'))
}
