import type { ApiUi } from 'back/src/apps/api-ui/boot'
import type { Elysia } from 'elysia'
import type { TreatyToPath } from '~/utils/eden'
import type { FetchOpts } from '~/utils/fetcher'

type Create<App extends Elysia<any, any, any, any, any, any, any, any>> =
    App extends {
        _routes: infer Schema extends Record<string, unknown>
        // @ts-expect-error skip
    } ? Fn<TreatyToPath<Schema>> : 'Please install Elysia before using Eden'

type Fn<Schema extends Record<string, any>> = <
    Endpoint extends keyof Schema,
    Method extends Uppercase<Extract<keyof Schema[Endpoint], string>>,
    Route extends Schema[Endpoint][Lowercase<Method>],
>(
    endpoint: Endpoint,
    body?: Route['body'],
    opts?: FetchOpts
) => Promise<Awaited<Route['response'][200]>>

function ServerEntity<App extends Elysia<any, any, any, any, any, any, any, any>>(): Create<App> {
    // @ts-expect-error skip
    return (
        endpoint: string,
        body: Record<string, any> | FormData = {},
        opts?: FetchOpts,
    ) => new Promise((resolve, reject) => {
        const { exec } = fetcher({
            ...opts,
            makeUrl: () => endpoint,
            makeBody: () => body ?? {},
            onResponse: json => resolve(json),
            onError: err => reject(err),
        })
        exec()
    })
}

// !i! слово "useFetch" - зарезервировано

export const useFetchCall = ServerEntity<ApiUi>()
