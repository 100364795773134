import { default as hierarchyx3EhfN3VpGMeta } from "/app/packages/front/pages/abilities/[sid]/hierarchy.vue?macro=true";
import { default as historyaP4nPsyM4fMeta } from "/app/packages/front/pages/abilities/[sid]/history.vue?macro=true";
import { default as indexTlcsX3cLEWMeta } from "/app/packages/front/pages/abilities/[sid]/index.vue?macro=true";
import { default as _91sid_936YSlMM2sOpMeta } from "/app/packages/front/pages/abilities/[sid].vue?macro=true";
import { default as index1UlGYVP6MAMeta } from "/app/packages/front/pages/abilities/index.vue?macro=true";
import { default as characteristicsz615wr0GCSMeta } from "/app/packages/front/pages/characteristics-columns/[sid]/characteristics.vue?macro=true";
import { default as historyDs9BNQCq4ZMeta } from "/app/packages/front/pages/characteristics-columns/[sid]/history.vue?macro=true";
import { default as indextrSUWfDYvOMeta } from "/app/packages/front/pages/characteristics-columns/[sid]/index.vue?macro=true";
import { default as _91sid_939dAKOcNDP4Meta } from "/app/packages/front/pages/characteristics-columns/[sid].vue?macro=true";
import { default as indexDoplmUh1gHMeta } from "/app/packages/front/pages/characteristics-columns/index.vue?macro=true";
import { default as characteristicss06xwscaBRMeta } from "/app/packages/front/pages/characteristics-groups/[sid]/characteristics.vue?macro=true";
import { default as historyejFcikj5NFMeta } from "/app/packages/front/pages/characteristics-groups/[sid]/history.vue?macro=true";
import { default as indexbYAmQP65KEMeta } from "/app/packages/front/pages/characteristics-groups/[sid]/index.vue?macro=true";
import { default as _91sid_93s8gcmX702zMeta } from "/app/packages/front/pages/characteristics-groups/[sid].vue?macro=true";
import { default as index7rPMSptIGaMeta } from "/app/packages/front/pages/characteristics-groups/index.vue?macro=true";
import { default as historyg5oBVrVAtDMeta } from "/app/packages/front/pages/characteristics/[sid]/history.vue?macro=true";
import { default as index9Qb7KLtAiVMeta } from "/app/packages/front/pages/characteristics/[sid]/index.vue?macro=true";
import { default as _91sid_93fbBWI8oTFXMeta } from "/app/packages/front/pages/characteristics/[sid].vue?macro=true";
import { default as indexy5TioU9mCpMeta } from "/app/packages/front/pages/characteristics/index.vue?macro=true";
import { default as indexgNapU9iO9KMeta } from "/app/packages/front/pages/competences/[sid]/index.vue?macro=true";
import { default as usersiVagclcfSrMeta } from "/app/packages/front/pages/competences/[sid]/users.vue?macro=true";
import { default as _91sid_93BXMauKr5RZMeta } from "/app/packages/front/pages/competences/[sid].vue?macro=true";
import { default as indexgRDIOMJXaGMeta } from "/app/packages/front/pages/competences/index.vue?macro=true";
import { default as historyuIcyuTNo1hMeta } from "/app/packages/front/pages/countries/[sid]/history.vue?macro=true";
import { default as indexsi0gG3MIgJMeta } from "/app/packages/front/pages/countries/[sid]/index.vue?macro=true";
import { default as _91sid_93iO2qbONHkAMeta } from "/app/packages/front/pages/countries/[sid].vue?macro=true";
import { default as index4gh5pVjmNYMeta } from "/app/packages/front/pages/countries/index.vue?macro=true";
import { default as historyp0FUOBRXYbMeta } from "/app/packages/front/pages/currencies/[sid]/history.vue?macro=true";
import { default as indexdnK3QoBOEOMeta } from "/app/packages/front/pages/currencies/[sid]/index.vue?macro=true";
import { default as _91sid_93XRTr7eRyRrMeta } from "/app/packages/front/pages/currencies/[sid].vue?macro=true";
import { default as index2GkJVuylVgMeta } from "/app/packages/front/pages/currencies/index.vue?macro=true";
import { default as index3Y5w4BLUiDMeta } from "/app/packages/front/pages/dev/index.vue?macro=true";
import { default as historySnzIxL8cOeMeta } from "/app/packages/front/pages/dfc/[sid]/history.vue?macro=true";
import { default as indexS1FbRwFT5tMeta } from "/app/packages/front/pages/dfc/[sid]/index.vue?macro=true";
import { default as _91sid_93PQTpyV5BiuMeta } from "/app/packages/front/pages/dfc/[sid].vue?macro=true";
import { default as indexuXSfSg2lXCMeta } from "/app/packages/front/pages/dfc/index.vue?macro=true";
import { default as idqBRL3psvqUMeta } from "/app/packages/front/pages/id.vue?macro=true";
import { default as indexFUY8jIsNMlMeta } from "/app/packages/front/pages/index.vue?macro=true";
import { default as detailoglSgOLPNOMeta } from "/app/packages/front/pages/languages/[sid]/detail.vue?macro=true";
import { default as indexPQPY3IxaaWMeta } from "/app/packages/front/pages/languages/[sid]/index.vue?macro=true";
import { default as testnD3UaJApEoMeta } from "/app/packages/front/pages/languages/[sid]/test.vue?macro=true";
import { default as _91sid_93RTsh0y47B0Meta } from "/app/packages/front/pages/languages/[sid].vue?macro=true";
import { default as index88MFGq8L2EMeta } from "/app/packages/front/pages/languages/index.vue?macro=true";
import { default as historyUhpJktVp5LMeta } from "/app/packages/front/pages/manufacturers/[sid]/history.vue?macro=true";
import { default as indexXOzTDrgzRrMeta } from "/app/packages/front/pages/manufacturers/[sid]/index.vue?macro=true";
import { default as _91sid_93jehzeRxFjnMeta } from "/app/packages/front/pages/manufacturers/[sid].vue?macro=true";
import { default as indexXje8L7XFQCMeta } from "/app/packages/front/pages/manufacturers/index.vue?macro=true";
import { default as historyjAu7WJG75YMeta } from "/app/packages/front/pages/materials-classes/[sid]/history.vue?macro=true";
import { default as index6P4ENn5IOGMeta } from "/app/packages/front/pages/materials-classes/[sid]/index.vue?macro=true";
import { default as _91sid_93JGsbVocYb6Meta } from "/app/packages/front/pages/materials-classes/[sid].vue?macro=true";
import { default as indexDD8EVD0ZrgMeta } from "/app/packages/front/pages/materials-classes/index.vue?macro=true";
import { default as historya2XXiJ7vGIMeta } from "/app/packages/front/pages/materials-types/[sid]/history.vue?macro=true";
import { default as indexXZiLpKZJ6hMeta } from "/app/packages/front/pages/materials-types/[sid]/index.vue?macro=true";
import { default as _91sid_93mnrQ6dsUBSMeta } from "/app/packages/front/pages/materials-types/[sid].vue?macro=true";
import { default as indexRNPgL47ANRMeta } from "/app/packages/front/pages/materials-types/index.vue?macro=true";
import { default as fields_45groupsdj6oMeqOB2Meta } from "/app/packages/front/pages/references/[sid]/fields-groups.vue?macro=true";
import { default as fieldsE0qgeTzLgtMeta } from "/app/packages/front/pages/references/[sid]/fields.vue?macro=true";
import { default as historyQ6541UVflzMeta } from "/app/packages/front/pages/references/[sid]/history.vue?macro=true";
import { default as indexZNb1DHuGykMeta } from "/app/packages/front/pages/references/[sid]/index.vue?macro=true";
import { default as recordsuN9QSVV2CVMeta } from "/app/packages/front/pages/references/[sid]/records.vue?macro=true";
import { default as _91sid_93beu9f7cwCyMeta } from "/app/packages/front/pages/references/[sid].vue?macro=true";
import { default as indexArqvPwZasRMeta } from "/app/packages/front/pages/references/index.vue?macro=true";
import { default as historypZ7sFW5CJuMeta } from "/app/packages/front/pages/system-entities/[sid]/history.vue?macro=true";
import { default as index8JkBDeq5MbMeta } from "/app/packages/front/pages/system-entities/[sid]/index.vue?macro=true";
import { default as _91sid_93fkFRn9TL3UMeta } from "/app/packages/front/pages/system-entities/[sid].vue?macro=true";
import { default as indexoSkz1IPj4bMeta } from "/app/packages/front/pages/system-entities/index.vue?macro=true";
import { default as history29Uo8RJBg0Meta } from "/app/packages/front/pages/system-tables/[sid]/history.vue?macro=true";
import { default as indexYu396ByqS6Meta } from "/app/packages/front/pages/system-tables/[sid]/index.vue?macro=true";
import { default as _91sid_93dxzgClKxCAMeta } from "/app/packages/front/pages/system-tables/[sid].vue?macro=true";
import { default as index11Yoz8LZyQMeta } from "/app/packages/front/pages/system-tables/index.vue?macro=true";
import { default as indexjmyhPH8QKeMeta } from "/app/packages/front/pages/test/[id]/index.vue?macro=true";
import { default as tab1aB9tEZ8K2LMeta } from "/app/packages/front/pages/test/[id]/tab1.vue?macro=true";
import { default as tab2ESshdQ6mhJMeta } from "/app/packages/front/pages/test/[id]/tab2.vue?macro=true";
import { default as _91id_93SbkMCIq36LMeta } from "/app/packages/front/pages/test/[id].vue?macro=true";
import { default as import_45fpizUaGrYb404Meta } from "/app/packages/front/pages/test/import-fpi.vue?macro=true";
import { default as import_45ing9hj2nK5t9XMeta } from "/app/packages/front/pages/test/import-ing.vue?macro=true";
import { default as import_45pamL3kcO99dA1Meta } from "/app/packages/front/pages/test/import-pam.vue?macro=true";
import { default as indexNvvOoCTNVjMeta } from "/app/packages/front/pages/test/index.vue?macro=true";
import { default as historyReavIV8MNTMeta } from "/app/packages/front/pages/translations/[sid]/history.vue?macro=true";
import { default as indexsRhfSHvDSLMeta } from "/app/packages/front/pages/translations/[sid]/index.vue?macro=true";
import { default as test_45pluralkLxLuyArTWMeta } from "/app/packages/front/pages/translations/[sid]/test-plural.vue?macro=true";
import { default as _91sid_93bCU2vxrItfMeta } from "/app/packages/front/pages/translations/[sid].vue?macro=true";
import { default as import3h2AmpP0wpMeta } from "/app/packages/front/pages/translations/import.vue?macro=true";
import { default as indexqcZjuuLtj0Meta } from "/app/packages/front/pages/translations/index.vue?macro=true";
import { default as dialogtnQdSUW0d0Meta } from "/app/packages/front/pages/ui/dialog.vue?macro=true";
import { default as formaL8XHAhgkcMeta } from "/app/packages/front/pages/ui/form.vue?macro=true";
import { default as iconoKhRtulmEEMeta } from "/app/packages/front/pages/ui/icon.vue?macro=true";
import { default as index1S86nwCpovMeta } from "/app/packages/front/pages/ui/index.vue?macro=true";
import { default as list0qrHuuFGhOMeta } from "/app/packages/front/pages/ui/list.vue?macro=true";
import { default as messagestmHzsP9cvMeta } from "/app/packages/front/pages/ui/message.vue?macro=true";
import { default as treehrThUij4qgMeta } from "/app/packages/front/pages/ui/tree.vue?macro=true";
import { default as value_45view_45formattero2W3mHmX4eMeta } from "/app/packages/front/pages/ui/value-view-formatter.vue?macro=true";
import { default as virtual_45scroll0WZWw6TB1dMeta } from "/app/packages/front/pages/ui/virtual-scroll.vue?macro=true";
import { default as history19p4l0l0kRMeta } from "/app/packages/front/pages/units-groups/[sid]/history.vue?macro=true";
import { default as indexk0xmlJcb8CMeta } from "/app/packages/front/pages/units-groups/[sid]/index.vue?macro=true";
import { default as unitsBsSF1WmpFIMeta } from "/app/packages/front/pages/units-groups/[sid]/units.vue?macro=true";
import { default as _91sid_93bYYwt0GJWIMeta } from "/app/packages/front/pages/units-groups/[sid].vue?macro=true";
import { default as indexeK2ZvNtYsyMeta } from "/app/packages/front/pages/units-groups/index.vue?macro=true";
import { default as historydgd1BGlNQsMeta } from "/app/packages/front/pages/units/[sid]/history.vue?macro=true";
import { default as indexHSIOAkRYClMeta } from "/app/packages/front/pages/units/[sid]/index.vue?macro=true";
import { default as _91sid_93oZ1pm4wzeLMeta } from "/app/packages/front/pages/units/[sid].vue?macro=true";
import { default as indexgpe4vUdU1NMeta } from "/app/packages/front/pages/units/index.vue?macro=true";
import { default as historyLYmAf70L8BMeta } from "/app/packages/front/pages/users/[sid]/history.vue?macro=true";
import { default as indexbadub1rldOMeta } from "/app/packages/front/pages/users/[sid]/index.vue?macro=true";
import { default as _91sid_93IdMYv8im4ZMeta } from "/app/packages/front/pages/users/[sid].vue?macro=true";
import { default as indexpkuKb7vhEQMeta } from "/app/packages/front/pages/users/index.vue?macro=true";
import { default as historysKJ6iaz5gjMeta } from "/app/packages/front/pages/workgroups/[sid]/history.vue?macro=true";
import { default as indexGE8lWV23hFMeta } from "/app/packages/front/pages/workgroups/[sid]/index.vue?macro=true";
import { default as usersDsapwcpaYyMeta } from "/app/packages/front/pages/workgroups/[sid]/users.vue?macro=true";
import { default as _91sid_93Tpp3o9Kta9Meta } from "/app/packages/front/pages/workgroups/[sid].vue?macro=true";
import { default as indexoNekwJJrEZMeta } from "/app/packages/front/pages/workgroups/index.vue?macro=true";
export default [
  {
    name: _91sid_936YSlMM2sOpMeta?.name,
    path: "/abilities/:sid()",
    component: () => import("/app/packages/front/pages/abilities/[sid].vue"),
    children: [
  {
    name: "abilities-sid-hierarchy",
    path: "hierarchy",
    component: () => import("/app/packages/front/pages/abilities/[sid]/hierarchy.vue")
  },
  {
    name: "abilities-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/abilities/[sid]/history.vue")
  },
  {
    name: "abilities-sid",
    path: "",
    component: () => import("/app/packages/front/pages/abilities/[sid]/index.vue")
  }
]
  },
  {
    name: "abilities",
    path: "/abilities",
    component: () => import("/app/packages/front/pages/abilities/index.vue")
  },
  {
    name: _91sid_939dAKOcNDP4Meta?.name,
    path: "/characteristics-columns/:sid()",
    component: () => import("/app/packages/front/pages/characteristics-columns/[sid].vue"),
    children: [
  {
    name: "characteristics-columns-sid-characteristics",
    path: "characteristics",
    component: () => import("/app/packages/front/pages/characteristics-columns/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-columns-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/characteristics-columns/[sid]/history.vue")
  },
  {
    name: "characteristics-columns-sid",
    path: "",
    component: () => import("/app/packages/front/pages/characteristics-columns/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-columns",
    path: "/characteristics-columns",
    component: () => import("/app/packages/front/pages/characteristics-columns/index.vue")
  },
  {
    name: _91sid_93s8gcmX702zMeta?.name,
    path: "/characteristics-groups/:sid()",
    component: () => import("/app/packages/front/pages/characteristics-groups/[sid].vue"),
    children: [
  {
    name: "characteristics-groups-sid-characteristics",
    path: "characteristics",
    component: () => import("/app/packages/front/pages/characteristics-groups/[sid]/characteristics.vue")
  },
  {
    name: "characteristics-groups-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/characteristics-groups/[sid]/history.vue")
  },
  {
    name: "characteristics-groups-sid",
    path: "",
    component: () => import("/app/packages/front/pages/characteristics-groups/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics-groups",
    path: "/characteristics-groups",
    component: () => import("/app/packages/front/pages/characteristics-groups/index.vue")
  },
  {
    name: _91sid_93fbBWI8oTFXMeta?.name,
    path: "/characteristics/:sid()",
    component: () => import("/app/packages/front/pages/characteristics/[sid].vue"),
    children: [
  {
    name: "characteristics-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/characteristics/[sid]/history.vue")
  },
  {
    name: "characteristics-sid",
    path: "",
    component: () => import("/app/packages/front/pages/characteristics/[sid]/index.vue")
  }
]
  },
  {
    name: "characteristics",
    path: "/characteristics",
    component: () => import("/app/packages/front/pages/characteristics/index.vue")
  },
  {
    name: _91sid_93BXMauKr5RZMeta?.name,
    path: "/competences/:sid()",
    component: () => import("/app/packages/front/pages/competences/[sid].vue"),
    children: [
  {
    name: "competences-sid",
    path: "",
    component: () => import("/app/packages/front/pages/competences/[sid]/index.vue")
  },
  {
    name: "competences-sid-users",
    path: "users",
    component: () => import("/app/packages/front/pages/competences/[sid]/users.vue")
  }
]
  },
  {
    name: "competences",
    path: "/competences",
    component: () => import("/app/packages/front/pages/competences/index.vue")
  },
  {
    name: _91sid_93iO2qbONHkAMeta?.name,
    path: "/countries/:sid()",
    component: () => import("/app/packages/front/pages/countries/[sid].vue"),
    children: [
  {
    name: "countries-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/countries/[sid]/history.vue")
  },
  {
    name: "countries-sid",
    path: "",
    component: () => import("/app/packages/front/pages/countries/[sid]/index.vue")
  }
]
  },
  {
    name: "countries",
    path: "/countries",
    component: () => import("/app/packages/front/pages/countries/index.vue")
  },
  {
    name: _91sid_93XRTr7eRyRrMeta?.name,
    path: "/currencies/:sid()",
    component: () => import("/app/packages/front/pages/currencies/[sid].vue"),
    children: [
  {
    name: "currencies-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/currencies/[sid]/history.vue")
  },
  {
    name: "currencies-sid",
    path: "",
    component: () => import("/app/packages/front/pages/currencies/[sid]/index.vue")
  }
]
  },
  {
    name: "currencies",
    path: "/currencies",
    component: () => import("/app/packages/front/pages/currencies/index.vue")
  },
  {
    name: "dev",
    path: "/dev",
    component: () => import("/app/packages/front/pages/dev/index.vue")
  },
  {
    name: _91sid_93PQTpyV5BiuMeta?.name,
    path: "/dfc/:sid()",
    component: () => import("/app/packages/front/pages/dfc/[sid].vue"),
    children: [
  {
    name: "dfc-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/dfc/[sid]/history.vue")
  },
  {
    name: "dfc-sid",
    path: "",
    component: () => import("/app/packages/front/pages/dfc/[sid]/index.vue")
  }
]
  },
  {
    name: "dfc",
    path: "/dfc",
    component: () => import("/app/packages/front/pages/dfc/index.vue")
  },
  {
    name: "id",
    path: "/id",
    component: () => import("/app/packages/front/pages/id.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/packages/front/pages/index.vue")
  },
  {
    name: _91sid_93RTsh0y47B0Meta?.name,
    path: "/languages/:sid()",
    component: () => import("/app/packages/front/pages/languages/[sid].vue"),
    children: [
  {
    name: "languages-sid-detail",
    path: "detail",
    component: () => import("/app/packages/front/pages/languages/[sid]/detail.vue")
  },
  {
    name: "languages-sid",
    path: "",
    component: () => import("/app/packages/front/pages/languages/[sid]/index.vue")
  },
  {
    name: "languages-sid-test",
    path: "test",
    component: () => import("/app/packages/front/pages/languages/[sid]/test.vue")
  }
]
  },
  {
    name: "languages",
    path: "/languages",
    component: () => import("/app/packages/front/pages/languages/index.vue")
  },
  {
    name: _91sid_93jehzeRxFjnMeta?.name,
    path: "/manufacturers/:sid()",
    component: () => import("/app/packages/front/pages/manufacturers/[sid].vue"),
    children: [
  {
    name: "manufacturers-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/manufacturers/[sid]/history.vue")
  },
  {
    name: "manufacturers-sid",
    path: "",
    component: () => import("/app/packages/front/pages/manufacturers/[sid]/index.vue")
  }
]
  },
  {
    name: "manufacturers",
    path: "/manufacturers",
    component: () => import("/app/packages/front/pages/manufacturers/index.vue")
  },
  {
    name: _91sid_93JGsbVocYb6Meta?.name,
    path: "/materials-classes/:sid()",
    component: () => import("/app/packages/front/pages/materials-classes/[sid].vue"),
    children: [
  {
    name: "materials-classes-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/materials-classes/[sid]/history.vue")
  },
  {
    name: "materials-classes-sid",
    path: "",
    component: () => import("/app/packages/front/pages/materials-classes/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-classes",
    path: "/materials-classes",
    component: () => import("/app/packages/front/pages/materials-classes/index.vue")
  },
  {
    name: _91sid_93mnrQ6dsUBSMeta?.name,
    path: "/materials-types/:sid()",
    component: () => import("/app/packages/front/pages/materials-types/[sid].vue"),
    children: [
  {
    name: "materials-types-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/materials-types/[sid]/history.vue")
  },
  {
    name: "materials-types-sid",
    path: "",
    component: () => import("/app/packages/front/pages/materials-types/[sid]/index.vue")
  }
]
  },
  {
    name: "materials-types",
    path: "/materials-types",
    component: () => import("/app/packages/front/pages/materials-types/index.vue")
  },
  {
    name: _91sid_93beu9f7cwCyMeta?.name,
    path: "/references/:sid()",
    component: () => import("/app/packages/front/pages/references/[sid].vue"),
    children: [
  {
    name: "references-sid-fields-groups",
    path: "fields-groups",
    component: () => import("/app/packages/front/pages/references/[sid]/fields-groups.vue")
  },
  {
    name: "references-sid-fields",
    path: "fields",
    component: () => import("/app/packages/front/pages/references/[sid]/fields.vue")
  },
  {
    name: "references-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/references/[sid]/history.vue")
  },
  {
    name: "references-sid",
    path: "",
    component: () => import("/app/packages/front/pages/references/[sid]/index.vue")
  },
  {
    name: "references-sid-records",
    path: "records",
    component: () => import("/app/packages/front/pages/references/[sid]/records.vue")
  }
]
  },
  {
    name: "references",
    path: "/references",
    component: () => import("/app/packages/front/pages/references/index.vue")
  },
  {
    name: _91sid_93fkFRn9TL3UMeta?.name,
    path: "/system-entities/:sid()",
    component: () => import("/app/packages/front/pages/system-entities/[sid].vue"),
    children: [
  {
    name: "system-entities-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/system-entities/[sid]/history.vue")
  },
  {
    name: "system-entities-sid",
    path: "",
    component: () => import("/app/packages/front/pages/system-entities/[sid]/index.vue")
  }
]
  },
  {
    name: "system-entities",
    path: "/system-entities",
    component: () => import("/app/packages/front/pages/system-entities/index.vue")
  },
  {
    name: _91sid_93dxzgClKxCAMeta?.name,
    path: "/system-tables/:sid()",
    component: () => import("/app/packages/front/pages/system-tables/[sid].vue"),
    children: [
  {
    name: "system-tables-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/system-tables/[sid]/history.vue")
  },
  {
    name: "system-tables-sid",
    path: "",
    component: () => import("/app/packages/front/pages/system-tables/[sid]/index.vue")
  }
]
  },
  {
    name: "system-tables",
    path: "/system-tables",
    component: () => import("/app/packages/front/pages/system-tables/index.vue")
  },
  {
    name: _91id_93SbkMCIq36LMeta?.name,
    path: "/test/:id()",
    component: () => import("/app/packages/front/pages/test/[id].vue"),
    children: [
  {
    name: "test-id",
    path: "",
    component: () => import("/app/packages/front/pages/test/[id]/index.vue")
  },
  {
    name: "test-id-tab1",
    path: "tab1",
    component: () => import("/app/packages/front/pages/test/[id]/tab1.vue")
  },
  {
    name: "test-id-tab2",
    path: "tab2",
    component: () => import("/app/packages/front/pages/test/[id]/tab2.vue")
  }
]
  },
  {
    name: "test-import-fpi",
    path: "/test/import-fpi",
    component: () => import("/app/packages/front/pages/test/import-fpi.vue")
  },
  {
    name: "test-import-ing",
    path: "/test/import-ing",
    component: () => import("/app/packages/front/pages/test/import-ing.vue")
  },
  {
    name: "test-import-pam",
    path: "/test/import-pam",
    component: () => import("/app/packages/front/pages/test/import-pam.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/app/packages/front/pages/test/index.vue")
  },
  {
    name: _91sid_93bCU2vxrItfMeta?.name,
    path: "/translations/:sid()",
    component: () => import("/app/packages/front/pages/translations/[sid].vue"),
    children: [
  {
    name: "translations-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/translations/[sid]/history.vue")
  },
  {
    name: "translations-sid",
    path: "",
    component: () => import("/app/packages/front/pages/translations/[sid]/index.vue")
  },
  {
    name: "translations-sid-test-plural",
    path: "test-plural",
    component: () => import("/app/packages/front/pages/translations/[sid]/test-plural.vue")
  }
]
  },
  {
    name: "translations-import",
    path: "/translations/import",
    component: () => import("/app/packages/front/pages/translations/import.vue")
  },
  {
    name: "translations",
    path: "/translations",
    component: () => import("/app/packages/front/pages/translations/index.vue")
  },
  {
    name: "ui-dialog",
    path: "/ui/dialog",
    component: () => import("/app/packages/front/pages/ui/dialog.vue")
  },
  {
    name: "ui-form",
    path: "/ui/form",
    component: () => import("/app/packages/front/pages/ui/form.vue")
  },
  {
    name: "ui-icon",
    path: "/ui/icon",
    component: () => import("/app/packages/front/pages/ui/icon.vue")
  },
  {
    name: "ui",
    path: "/ui",
    component: () => import("/app/packages/front/pages/ui/index.vue")
  },
  {
    name: "ui-list",
    path: "/ui/list",
    component: () => import("/app/packages/front/pages/ui/list.vue")
  },
  {
    name: "ui-message",
    path: "/ui/message",
    component: () => import("/app/packages/front/pages/ui/message.vue")
  },
  {
    name: "ui-tree",
    path: "/ui/tree",
    component: () => import("/app/packages/front/pages/ui/tree.vue")
  },
  {
    name: "ui-value-view-formatter",
    path: "/ui/value-view-formatter",
    component: () => import("/app/packages/front/pages/ui/value-view-formatter.vue")
  },
  {
    name: "ui-virtual-scroll",
    path: "/ui/virtual-scroll",
    component: () => import("/app/packages/front/pages/ui/virtual-scroll.vue")
  },
  {
    name: _91sid_93bYYwt0GJWIMeta?.name,
    path: "/units-groups/:sid()",
    component: () => import("/app/packages/front/pages/units-groups/[sid].vue"),
    children: [
  {
    name: "units-groups-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/units-groups/[sid]/history.vue")
  },
  {
    name: "units-groups-sid",
    path: "",
    component: () => import("/app/packages/front/pages/units-groups/[sid]/index.vue")
  },
  {
    name: "units-groups-sid-units",
    path: "units",
    component: () => import("/app/packages/front/pages/units-groups/[sid]/units.vue")
  }
]
  },
  {
    name: "units-groups",
    path: "/units-groups",
    component: () => import("/app/packages/front/pages/units-groups/index.vue")
  },
  {
    name: _91sid_93oZ1pm4wzeLMeta?.name,
    path: "/units/:sid()",
    component: () => import("/app/packages/front/pages/units/[sid].vue"),
    children: [
  {
    name: "units-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/units/[sid]/history.vue")
  },
  {
    name: "units-sid",
    path: "",
    component: () => import("/app/packages/front/pages/units/[sid]/index.vue")
  }
]
  },
  {
    name: "units",
    path: "/units",
    component: () => import("/app/packages/front/pages/units/index.vue")
  },
  {
    name: _91sid_93IdMYv8im4ZMeta?.name,
    path: "/users/:sid()",
    component: () => import("/app/packages/front/pages/users/[sid].vue"),
    children: [
  {
    name: "users-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/users/[sid]/history.vue")
  },
  {
    name: "users-sid",
    path: "",
    component: () => import("/app/packages/front/pages/users/[sid]/index.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/app/packages/front/pages/users/index.vue")
  },
  {
    name: _91sid_93Tpp3o9Kta9Meta?.name,
    path: "/workgroups/:sid()",
    component: () => import("/app/packages/front/pages/workgroups/[sid].vue"),
    children: [
  {
    name: "workgroups-sid-history",
    path: "history",
    component: () => import("/app/packages/front/pages/workgroups/[sid]/history.vue")
  },
  {
    name: "workgroups-sid",
    path: "",
    component: () => import("/app/packages/front/pages/workgroups/[sid]/index.vue")
  },
  {
    name: "workgroups-sid-users",
    path: "users",
    component: () => import("/app/packages/front/pages/workgroups/[sid]/users.vue")
  }
]
  },
  {
    name: "workgroups",
    path: "/workgroups",
    component: () => import("/app/packages/front/pages/workgroups/index.vue")
  }
]