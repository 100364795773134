<template>
    <TransitionGroup name="r-toast-group" class="r-toast-container" tag="div">
        <div v-for="(toast, index) in items" :key="`${toast.id}`" :class="getClasses(index)">
            <div class="r-toast-item-wrap">
                <RIcon
                    v-if="toast.type"
                    class="r-toast-type-icon"
                    width="19"
                    :color="iconColor(toast)"
                    :name="iconName(toast)"
                />
                <div class="r-toast-item-text-wrap">
                    <div v-if="toast.title" class="r-toast-header">{{ toast.title }}</div>
                    <div class="r-toast-content">
                        <div v-if="toast.content" v-html="toast.content" />
                        <component
                            :is="toast.component"
                            v-if="toast.component"
                            v-bind="toast.props ? toast.props : {}"
                            :to-id="toast.id"
                        />
                    </div>
                </div>
            </div>
            <RBtn
                mode="positive"
                icon="cancel"
                class="r-toast-close"
                @click="close({ id: toast.id as string | number, ref: 'closeBtn' })"
            />
        </div>
    </TransitionGroup>
</template>

<script lang="ts">
import RIcon from '../RIcon.vue'
import { usePopup } from './composables/usePopup'
import type { IToastOpts } from './types'

const { items, open, close, getClasses } = usePopup('toast')
export function _useToast() {
    return {
        open: (opts: IToastOpts | string) => open(opts),
        close,
    }
}

export default {
    components: { RIcon },
    setup() {
        return {
            items,
            getClasses,
            close,
        }
    },
    methods: {
        iconColor(item: IToastOpts) {
            switch (item.type) {
                case 'info':
                    return 'var(--blue-a) var(--blue-b)'
                case 'success':
                    return 'var(--green)'
                case 'warning':
                case 'error':
                    return 'tomato black'
                default:
                    return 'var(--grey)'
            }
        },
        iconName(item: IToastOpts) {
            switch (item.type) {
                case 'info':
                    return 'information'
                case 'success':
                    return 'ok'
                case 'warning':
                case 'error':
                    return 'warning'
                default:
                    return '' as any
            }
        },
    },
}
</script>

<style lang="scss">
.r-toast-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 2px;
    right: 15px;
    z-index: 400;
    box-sizing: border-box;
}

.r-toast {
    position: relative;
    display: flex;
    margin-left: auto;
    margin-bottom: 1em;
    flex-direction: column;
    background-color: var(--light-grey);
    color: var(--text-normal-grey);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 1em;
    width: fit-content;

    .r-toast-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px 8px;
    }

    .r-toast-item-wrap {
        display: flex;
    }

    .r-toast-item-text-wrap {
        display: flex;
        flex-direction: column;
    }

    .r-toast-header {
        padding-right: 20px;
        font-size: 16px;
        margin: 0 0 0.2em;
    }

    .r-toast-content {
        padding-right: 20px;
        position: relative;
        &.flex {
            align-items: center;
        }
    }

    .r-toast-type-icon {
        margin-right: 0.5em;
        margin-top: 0.25em;
    }

    &.info {
        background-color: var(--light-blue);
    }

    &.success {
        background-color: var(--light-green);
    }

    &.warning {
        background-color: var(--light-orange);
    }

    &.error {
        background-color: var(--light-red);
    }
}

.r-toast-group-enter-active,
.r-toast-group-leave-active {
    transition: all 0.4s ease;
}

.r-toast-group-enter-from,
.r-toast-group-leave-to {
    opacity: 0;
    transform: translateY(10px);
}
</style>
