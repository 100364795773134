import { breadcrumbsIntegration, extraErrorDataIntegration } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'

async function lazyLoadSentryIntegrations() {
    if (!process.client || process.env.NODE_ENV !== 'production') {
        // don't load on server
        return
    }

    Sentry.addIntegration(
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    )
}

function getSentryIntegrations() {
    if (!process.client) {
        // don't load on server
        return []
    }

    const router = useRouter()
    const browserTracing = Sentry.browserTracingIntegration({
        router,
    })

    return [
        breadcrumbsIntegration({
            console: false,
        }),
        extraErrorDataIntegration({
            depth: 5,
        }),
        browserTracing,
    ]
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp
        const config = useRuntimeConfig()

        let lastError: any
        Sentry.init({
            app: vueApp,
            dsn: 'http://deaddead363644f4b69c41b64258949f@localhost:33333/999',
            enabled: config.public.IS_DEV === 'N',
            environment: config.public.ENV,
            release: 'v0.0.0-dev', // config.public.SPA_VERSION,
            logErrors: false,
            autoSessionTracking: config.public.ENV === 'production',
            integrations: getSentryIntegrations(),
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: config.public.ENV === 'production' ? 0.1 : 1,
            replaysOnErrorSampleRate: 1,
            normalizeDepth: 5,
            maxValueLength: 160,
            ignoreErrors: ['Warning', 'AbortError'],
            beforeSend: (ev, hint) => {
                if (config.public.IS_DEV === 'Y') {
                    // eslint-disable-next-line no-console
                    console.debug('Error->Sentry', lastError)
                    // don't send
                    return null
                }
                return ev
            },
        })

        // noinspection ES6MissingAwait
        lazyLoadSentryIntegrations()

        return {
            provide: {
                sentry: Sentry,
            },
        }
    },
})
