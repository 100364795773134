<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
    <RModal />
    <RDialog />
    <RToast />
    <AppLoading />
</template>

<script setup lang="ts">
import RDialog from '~/components/UiKit/modal/RDialog.vue'
import RModal from '~/components/UiKit/modal/RModal.vue'
import RToast from '~/components/UiKit/modal/RToast.vue'
import { useAppStore } from '~/stores/app'

/**
 * Local
 */
const d = document
let timer: ReturnType<typeof setTimeout>
let watcher: ReturnType<typeof setInterval>
let lastActiveTime = Date.now()

/**
 * Hooks
 */
onMounted(() => {
    watcher = setInterval(() => {
        // установить флаг, активна вкладка или спит
        const sleep = Date.now() - lastActiveTime > 60_000
        useAppStore().windowActive = !sleep
    }, 10_000)

    // подавать признаки активности во вкладке
    d.addEventListener('keydown', beep)
    d.addEventListener('mousemove', beep)
})

onUnmounted(() => {
    clearInterval(watcher)
    d.removeEventListener('keydown', beep)
    d.removeEventListener('mousemove', beep)
})

/**
 * Methods
 */
function beep() {
    clearTimeout(timer)
    timer = setTimeout(() => {
        lastActiveTime = Date.now()
    }, 700)
}
</script>
