import { useToast } from '~/components/UiKit'

export function useAppError() {
    function show(content: string, title?: string) {
        useToast().open({
            id: 'appError',
            type: 'error',
            content,
            title,
            duration: 30_000,
        })
    }

    return { show }
}
