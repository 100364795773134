interface ConfigStore {
    alphabet: string
}

const updating = ref(false)
export const useConfigStore = defineStore('config', {
    state: (): ConfigStore => ({
        alphabet: 'A-Za-zА-Яа-яЁё',
    }),
    actions: {
        updating() {
            return updating.value
        },
        async setValue(key: keyof ConfigStore, value: string) {
            updating.value = true
            this.$state = await useFetchCall('/configUpdate', { [key]: value }, { background: true })
            updating.value = false
        },
    },
})
